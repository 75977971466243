import store from '../store'
import request from '../utils/request'
import constants from '../utils/constants'
import EncryptApiUtil from '../utils/encryptApiUtil'
import localforage from 'localforage'
import { ref, get, onValue, runTransaction, update, increment } from 'firebase/database'
import _ from 'lodash'

export async function getEbookList(userLang, learnLang) {
  // 获取目标语言的电子书列表和翻译数据
  let currentLangData = {}
  let ebookData = await localforage.getItem('ebookData') || {}

  if (ebookData[learnLang]) {
    currentLangData = ebookData[learnLang]
  } else {
    currentLangData = await getEbookDataOnline(learnLang)
    ebookData[learnLang] = currentLangData
    await localforage.setItem('ebookData', ebookData)
  }

  // 获取进度数据
  let ebookProgressData = await localforage.getItem('ebookProgressData') || {}

  // 为电子书添加翻译和进度等信息
  let { ebookList, ebookNameTransList, ebookSummaryTransList } = currentLangData
  for (let ebookID in ebookList) {
    let ebookData = ebookList[ebookID]
    ebookData.id = ebookID

    let { icon } = ebookData
    ebookData.banner = icon.replace('book_cover', 'book_cover2')

    let transKey = constants.langsData[userLang]['ebookLang']
    let ebookNameTrans = ebookNameTransList[ebookID][transKey]
    let ebookSummaryTrans = ebookSummaryTransList[ebookID][transKey]
    ebookData.ebook_name_trans = ebookNameTrans
    ebookData.ebook_summary_trans = ebookSummaryTrans

    let ebookProgress = ebookProgressData?.[learnLang]?.[ebookID]
    if (ebookProgress) {
      let { progress, last_learn_time } = ebookProgress
      ebookData.progress = progress
      ebookData.last_learn_time = last_learn_time
    }
  }

  // 返回经过排序的电子书列表
  return Object.values(ebookList).sort((a, b) => a.sort_index - b.sort_index)
}

export async function getOneEbook(learnLang, ebookID) {
  let ebookDownloadData = await localforage.getItem('ebookDownloadData') || {}

  let oneEbookData = ebookDownloadData?.[learnLang]?.[ebookID]
  if (!oneEbookData) {
    oneEbookData = await getOneEbookDataOnline(learnLang, ebookID)
    _.set(ebookDownloadData, `${learnLang}.${ebookID}`, oneEbookData)
    await localforage.setItem('ebookDownloadData', ebookDownloadData)
  }

  return oneEbookData
}

export async function getSentTrans(userLang, learnLang, ebookID, sentID) {
  let transKey = constants.langsData[userLang]['ebookLang']
  let ebookSentTrans = await localforage.getItem('ebookSentTrans') || {}

  let sentTrans = ebookSentTrans?.[learnLang]?.[ebookID]?.[sentID]?.[transKey]
  if (!sentTrans) {
    sentTrans = await getSentTransOnline(learnLang, ebookID, sentID, transKey)
    _.set(ebookSentTrans, `${learnLang}.${ebookID}.${sentID}.${transKey}`, sentTrans)
    await localforage.setItem('ebookSentTrans', ebookSentTrans)
  }

  return sentTrans
}

export async function getWordTransAndExplain(userLang, learnLang, ebookID, sentID, trunkID, wordID, sentence, word, refreshFlag) {
  let transKey = constants.langsData[userLang]['ebookLang']
  let ebookWordTrans = await localforage.getItem('ebookWordTrans') || {}
  let ebookWordExplain = await localforage.getItem('ebookWordExplain') || {}

  let wordTrans = ebookWordTrans?.[learnLang]?.[ebookID]?.[sentID]?.[trunkID]?.[wordID]?.[transKey]
  let wordExplain = ebookWordExplain?.[learnLang]?.[ebookID]?.[sentID]?.[trunkID]?.[wordID]

  if (!wordTrans || !wordExplain || refreshFlag === 'refresh') {
    let { translationsIntoUserLanguage, ...values } = await getWordTransAndExplainOnline(userLang, learnLang, sentence, word)
    wordTrans = translationsIntoUserLanguage
    wordExplain = values
    _.set(ebookWordTrans, `${learnLang}.${ebookID}.${sentID}.${trunkID}.${wordID}.${transKey}`, wordTrans)
    _.set(ebookWordExplain, `${learnLang}.${ebookID}.${sentID}.${trunkID}.${wordID}`, wordExplain)
    await localforage.setItem('ebookWordTrans', ebookWordTrans)
    await localforage.setItem('ebookWordExplain', ebookWordExplain)
  }

  return {
    wordTrans,
    wordExplain
  }
}

export async function setEbookProgress(learnLang, ebookID, progress) {
  let ebookProgressData = await localforage.getItem('ebookProgressData') || {}

  let ebookProgress = {
    progress,
    last_learn_time: new Date().getTime()
  }

  _.set(ebookProgressData, `${learnLang}.${ebookID}`, ebookProgress)
  await localforage.setItem('ebookProgressData', ebookProgressData)
}


// -------------------------------- request --------------------------------

// 从firebase获取电子书的线上数据
async function getEbookDataOnline(learnLang) {
  let ebookListSnapshot = await get(ref(window.db_ebook_bookData, `/${learnLang}/ebook`))
  let ebookNameTransListSnapshot = await get(ref(window.db_ebook_bookData, `/${learnLang}/ebook_name_trans`))
  let ebookSummaryTransListSnapshot = await get(ref(window.db_ebook_bookData, `/${learnLang}/ebook_summary_trans`))

  let ebookList = ebookListSnapshot.val()
  let ebookNameTransList = ebookNameTransListSnapshot.val()
  let ebookSummaryTransList = ebookSummaryTransListSnapshot.val()

  let currentLangData = {
    ebookList,
    ebookNameTransList,
    ebookSummaryTransList
  }

  return currentLangData
}

// 从firebase获取一本电子书的线上数据
async function getOneEbookDataOnline(learnLang, ebookID) {
  let oneEbookDetailSnapshot = await get(ref(window.db_ebook_bookData, `/${learnLang}/ebook_detail/${ebookID}/chapters_ids_array/001/paragraphs_array`))
  let oneEbookParagraphDetailSnapshot = await get(ref(window.db_ebook_bookData, `/${learnLang}/ebook_paragraph_detail/${ebookID}/001`))

  let oneEbookDetail = oneEbookDetailSnapshot.val()
  let oneEbookParagraphDetail = oneEbookParagraphDetailSnapshot.val()

  let sentences = {}
  let details = {}
  for (let key in oneEbookDetail) {
    sentences[key] = oneEbookDetail[key]['paragraph_txt']
    details[key] = oneEbookParagraphDetail[key]
  }

  let oneEbookData = {
    sentences,
    details
  }

  return oneEbookData
}

// 从firebase获取一句话的翻译
async function getSentTransOnline(learnLang, ebookID, sentID, transKey) {
  let sentTransSnapshot = await get(ref(window.db_ebook_bookData, `/${learnLang}/ebook_paragraph_trans/${ebookID}/001/${sentID}/${transKey}`))
  let sentTrans = sentTransSnapshot.val()

  return sentTrans
}

// 调用openAI接口获取单词的翻译和解释
async function getWordTransAndExplainOnline(userLang, learnLang, sentence, word) {
  const enWordExplainSystemPrompt =
    "Now, you're the editor of a learner's dictionary. You'll explain the selected word based on the input sentence.\n" +
    "The explanation must include: {\"pronunciationGuides (using IPA)\", \"theWordForm (e.g. noun, verb)\", \"englishDefinitions (Explain in English, using simple language, under 20 words)\", \"translationsIntoUserLanguage\", \"newExampleSentence (in English)\"}.\n" +
    "For example:\n" +
    "[Input sentence: A charge of $799 will be incurred if the umbrella is not returned within 14 days.\n" +
    "Selected word: charge\n" +
    "User language: Simplified Chinese]\n" +
    ">>>Explanation: \n" +
    "{\n" +
    "\"pronunciationGuides\": \"/tʃɑrdʒ/\",\n" +
    "\"theWordForm\": \"noun\",\n" +
    "\"englishDefinitions\": \"the amount of money that somebody asks for goods and services\",\n" +
    "\"translationsIntoUserLanguage\": \"收费，费用\",\n" +
    "\"newExampleSentence\": \"They added a charge of $10 for shipping.\"\n" +
    "}"

  const esWordExplainSystemPrompt =
    "Now, you're the editor of a learner's dictionary. You'll explain the selected word based on the input sentence.\n" +
    "The explanation output in JSON must include: {\"theWordForm (e.g. s.f., adj.m., s.m., v., etc.)\",\n" +
    "\"simpleSpanishDefinitions (Explain in Spanish, using simple language, under 20 words)\",\n" +
    "\"translationsIntoUserLanguage\",\"newSpanishExampleSentence (Make a new sentence in Spanish to show the selected word used in context)\"}.\n" +
    "For example:\n" +
    "[Input sentence: Me gusta la rosa que está a la derecha.\n" +
    "Selected word: rosa\n" +
    "User language: Simplified Chinese]\n" +
    ">>>Explanation: \n" +
    "{\n" +
    "\"theWordForm\": \"s.f.\",\n" +
    "\"simpleSpanishDefinitions\": \"Es un tipo de flor que puede ser de muchos colores. Crece en un arbusto y es muy fragante. \",\n" +
    "\"translationsIntoUserLanguage\": \"玫瑰\",\n" +
    "\"newSpanishExampleSentence\": \"La rosa es una flor muy hermosa y delicada.\"\n" +
    "}"

  const frWordExplainSystemPrompt =
    "Now, you're the editor of a learner's dictionary. You'll explain the selected word based on the input sentence. The explanation must include: 1.\"pronunciationGuides\" (using IPA); 2. \"theWordForm\" (e.g. n.m., n.f., v., adj. etc.); 3. \"simpleFrenchDefinitions\" (Use ***simple French*** to explain the selected word, under 20 words); 4. \"translationsIntoUserLanguage\" (Translate the selected word into user language); 5.\"newFrenchExampleSentence\" (Make a new sentence in ***French*** to show the selected word used in context).\n" +
    "\n" +
    "Please preserve the overall formatting of my template, which is: \n" +
    "{\"pronunciationGuides\": \"\", \"theWordForm\": \"\", \"simpleFrenchDefinitions\": \"\", \"translationsIntoUserLanguage\": \"\", \"newFrenchExampleSentence\": \"\"}. \n" +
    "\n" +
    "For example:\n" +
    "[Input sentence: Je ne bois pas d'alcool.\n" +
    "Selected word: bois\n" +
    "User language: Simplified Chinese]\n" +
    ">>>Explanation: \n" +
    "{\n" +
    "\"pronunciationGuides\": \"/bwɑ/\", \n" +
    "\"theWordForm\": \"v.\", \n" +
    "\"simpleFrenchDefinitions\": \"forme du verbe 'boire' - On utilise 'bois' quand on parle de soi-même en train de boire quelque chose.\", \n" +
    "\"translationsIntoUserLanguage\": \"喝\", \n" +
    "\"newFrenchExampleSentence\": \"Il aime boire de l'eau en été.\"\n" +
    "}"

  // 系统prompt
  let systemContent = learnLang === 'es' ? esWordExplainSystemPrompt : learnLang === 'fr' ? frWordExplainSystemPrompt : enWordExplainSystemPrompt

  // 用户prompt
  let userContent =
    `Input sentence: ${sentence}\n` +
    `Selected word: ${word}\n` +
    `User language: ${constants.langsData[userLang]['englishName']}`

  // 封装prompt集合
  let messages = [
    { "role": "system", "content": systemContent },
    { "role": "user", "content": userContent }
  ]

  let req = {
    "ep_uid": store.getters?.userInfo?.UID || '',
    "model": "gpt-4o-mini",
    "temperature": 0.7,
    "n": 1,
    "messages": JSON.stringify(messages),
    "uversion": "web"
  }
  console.log(req);

  // 加密请求体
  let encryptData = EncryptApiUtil.encrypt(JSON.stringify(req))

  // 发起请求
  let res = await request({
    url: constants.chatCompletionsApi,
    method: 'POST',
    data: encryptData.token
  })

  // 解密响应体
  let decryptData = EncryptApiUtil.decrypt(res.data, encryptData.key, encryptData.iv)
  console.log(decryptData);
  decryptData = JSON.parse(decryptData)

  // openAI返回值解析
  let completions = JSON.parse(decryptData.result.completions)
  let returnContent = completions.choices[0].message.content
  let returnObj = JSON.parse(returnContent.replace('>>>Explanation: \n', ''))

  return returnObj
}

// 上报错误反馈
export function sendBugReport(userLang, learnLang, bugID, bugType) {
  let userLangKey = constants.langsData[userLang]['htmlLang']
  let updates = {}
  updates[`bug_report_v3/${learnLang}/${bugID}/${userLangKey}/issue_type_${bugType}`] = increment(1)
  update(ref(window.db_ebook_userData), updates)
}

export function getFavWords(uid, learnLang, ebookID, callback) {
  let favWords = {}
  let favWordsRef = ref(window.db_ebook_userData, `fav_words_v3/${uid}/${learnLang}/${ebookID}`)
  onValue(favWordsRef, (snapshot) => {
    if (snapshot.exists()) {
      favWords = snapshot.val()
    }
    callback(favWords)
  })
}

export function setFavWord(uid, learnLang, ebookID, favID, favStatus) {
  let favWordsRef = ref(window.db_ebook_userData, `fav_words_v3/${uid}/${learnLang}/${ebookID}/${favID}`)
  runTransaction(favWordsRef, (word) => {
    if (!word) {
      word = {}
      word['last_result'] = true
      word['last_study_time'] = 0
    }
    word['fav_status'] = favStatus
    word['fav_time'] = new Date().getTime()
    return word
  })
}

export function setFavWordResult(uid, learnLang, ebookID, favID, result) {
  let updates = {}
  updates[`fav_words_v3/${uid}/${learnLang}/${ebookID}/${favID}/last_result`] = result
  updates[`fav_words_v3/${uid}/${learnLang}/${ebookID}/${favID}/last_study_time`] = new Date().getTime()
  update(ref(window.db_ebook_userData), updates)
}